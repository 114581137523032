import { createUseStyles, Styles } from '@pff-consumer/tweed-ui/web-styles'
import { ButtonProps } from './button.props'

interface ButtonStyles {
  button: Styles
  buttonLogo: Styles
  buttonText: Styles
  spinnerContainer: Styles
}

export const useStyles = createUseStyles<ButtonStyles, ButtonProps>((theme) => {
  // PayPal button color is added here as it is not supported by tweed-ui's color palette
  const payPalBtnColor = '#F4CA43'
  return {
    button: (props) => {
      const baseStyles = {
        backgroundColor: theme.colors.inverseBackgroundColor,
        borderRadius: theme.calcUnit(theme.borderRadius.roundedDefault),
        color: theme.colors.webInverseTextColor,
        cursor: props.disabled ? 'auto' : 'pointer',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        opacity: props.disabled ? '80%' : 1,
        padding: props.size === 'lg' ? theme.calcUnit(theme.spacing.s5) : theme.calcUnit(theme.spacing.s4),
        width: '100%',
        minHeight: theme.calcUnit(60),
        position: 'relative',
      }

      switch (props.variant) {
        case 'Paypal': {
          return {
            ...baseStyles,
            backgroundColor: payPalBtnColor,
            padding: theme.calcUnit(theme.spacing.s4),
          }
        }
        case 'Apple': {
          if (props.size === 'lg') {
            return {
              ...baseStyles,
              padding: theme.calcUnit(props.isPending ? theme.spacing.s5 : theme.spacing.s8),
              '-webkit-appearance': '-apple-pay-button',
              // '-apple-pay-button-style': 'white-outline',
              // '-apple-pay-button-type': 'subscribe',
              /*
              The following styles would support older Safari browsers that done't have -webkit-appearance.
              However I doubt this will be an issue since Recurly requires a later version of Apple Pay to render.
              I'm leaving these here just in case we find the button rendering oddly in old versions of Safari.
            */
              // backgroundImage: '-webkit-named-image(apple-pay-logo-white)',
              // backgroundSize: '100% 50%',
              // backgroundRepeat: 'no-repeat',
              // backgroundPosition: '50% 50%',
              // boxSizing: 'border-box',
            }
          }
          return baseStyles
        }
        case 'Loading': {
          return {
            ...baseStyles,
            backgroundColor: theme.colors.buttonBorderColor,
            padding: theme.calcUnit(theme.spacing.s4),
          }
        }
        case 'Secondary': {
          return {
            ...baseStyles,
            color: theme.colors.textColor,
            backgroundColor: theme.colors.backgroundColor,
            borderWidth: theme.calcUnit(1),
            borderColor: theme.colors.webButtonBorder,
            borderRadius: theme.calcUnit(theme.borderRadius.roundedDefault),
          }
        }
        default: {
          return baseStyles
        }
      }
    },
    buttonLogo: {
      marginRight: theme.calcUnit(theme.spacing.s2),
    },
    spinnerContainer: ({ size }) => {
      if (size === 'sm') {
        return {
          position: 'absolute',
          left: '10%',
        }
      }
      return {}
    },
    buttonText: {
      fontFamily: 'Roboto',
      fontWeight: 500,
      fontSize: theme.calcUnit(16),
      lineHeight: theme.calcUnit(24),
    },
  }
})
